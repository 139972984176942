import React, { Component } from 'react';
import { generalActions } from '../Redux/Actions/generalActions';
import { connect } from 'react-redux';
import Header from '../Component/Header';
import moment from 'moment';
import Utility from '../Helpers/Utility';
import { englishStrings } from '../Languages/en_EN';
import { italianStrings } from '../Languages/it_IT';
import * as firebase from 'firebase';
import firestore from '../Helpers/firebase';

class AgendaScreenRoot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: this.props.match.params.idEvent,
            roomId: this.props.match.params.idElement,
            agendaId: this.props.match.params.idItem,
            filesList: [],
            sidebarOpen: false,
            event: 'default',
            parentInfo: 'default',
            participantInfo: 'default',
            roomsList: [],
            agendaFilesList: [],
            agendaLinkAddressToShow: " ",
            currentAgendaFile: "default",
            rootChildrenList: [],
            userStatus: 'initialized'
        };
        this.getUserPreference = this.getUserPreference.bind(this);
		this.goToSurveyPageModal = this.goToSurveyPageModal.bind(this);
        this.goToQuestionPage = this.goToQuestionPage.bind(this);
    }

    componentDidMount() {
        if (Utility.checkAppVersionChanged()) {
            Utility.initializeLocalStorage();
            Utility.setDefaultLanguage();
        }

        let participant = JSON.parse(localStorage.getItem('participant-' + this.state.eventId));
        if (!participant) {
            this.props.history.push("/");
        } else {
        if (this.unsubscribeEventInfoChange) {
            this.unsubscribeEventInfoChange();
        }
        this.props.onEventInfoChange(this.state.eventId, (callback, unsubscribe) => {
            this.unsubscribeEventInfoChange = unsubscribe;

                if (callback.data.eventStatus === "Archived") {
                    window.location.href = "/";
                }

                this.setState({
                    ...this.state,
                    event: callback.data,
                    participantInfo: participant
                });

                if (!callback.data.availableLanguages.lang_it || !callback.data.availableLanguages.lang_en) {
                    if (callback.data.availableLanguages.lang_it) {
                        this.onSelectLanguage("Italian");
                    } else if (callback.data.availableLanguages.lang_en) {
                        this.onSelectLanguage("English");
                    }
                }                
        });
        var storedVotes = JSON.parse(localStorage.getItem("storedVotes"));
        var storedAnswersText = JSON.parse(localStorage.getItem("storedAnswersText"));

        if (this.unsubscribeGetListOfRootChildrenOnChange) {
            this.unsubscribeGetListOfRootChildrenOnChange();
        }
        this.props.onGetListOfRootChildrenOnChange(this.state.eventId, (rootChildrenList, unsubscribe) => {
            this.unsubscribeGetListOfRootChildrenOnChange = unsubscribe;
            let orederdRootChildrenList = [];
            let orderedRoomsChildrenList = [];
            let allElementsList = [];
            for (var i = 0; i < rootChildrenList.length; i++) {
                if (rootChildrenList[i].sParentId === "default") {
                    orederdRootChildrenList.push(rootChildrenList[i]);
                } else if (rootChildrenList[i].sParentId === this.state.roomId) {
                    orderedRoomsChildrenList.push(rootChildrenList[i]);
                }
            }

            orederdRootChildrenList.sort((a, b) => a.iOrder > b.iOrder ? 1 : -1);
            orderedRoomsChildrenList.sort((a, b) => a.iOrder > b.iOrder ? 1 : -1);
            allElementsList = orderedRoomsChildrenList.concat(orederdRootChildrenList);
                allElementsList.map((item, index) => {
                    if (item.sType === 'survey') {

                        if (this.unsubscribeGetSurveyFiles) {
                            this.unsubscribeGetSurveyFiles();
                        }
                        this.props.onGetSurveyFiles(this.state.eventId, item.id, (surveyFilesList, unsubscribe) => {
                            this.unsubscribeGetSurveyFiles = unsubscribe;
    
                            surveyFilesList.forEach(surveyfile => {
                                if (surveyfile) {
                                    item.surveyLinkUrl = surveyfile.fileUrl;
                                }
                            });
    
                        });
    
                    }
                });
            this.setState({ ...this.state, rootChildrenList: allElementsList });
        });

        if (this.unsubscribeGetEventRoomsOnChange) {
            this.unsubscribeGetEventRoomsOnChange();
        }
        this.props.onGetEventRoomsOnChange(this.state.eventId, (roomsList, unsubscribe) => {
            this.unsubscribeGetEventRoomsOnChange = unsubscribe;
            if (roomsList.length > 0) {
                this.setState({
                    ...this.state,
                    roomsList: roomsList
                });
            }
        });
        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }
        this.props.onGetParentInfoOnChange(this.state.eventId, this.state.roomId, (parentInfo, unsubscribe) => {
            this.unsubscribeGetParentInfoOnChange = unsubscribe;
            this.setState({
                ...this.state,
                parentInfo: parentInfo.data
            });
                var userAlreadyVoted = false;
            if (parentInfo.message === 'success') {
                if (parentInfo.data.currentLiveSession.status === "live") {

                    //we only go to the tweet question if the user did not already voted for the live question
                        if (userAlreadyVoted === false && storedVotes && storedVotes.includes(parentInfo.data.currentLiveSession.id)) {
                            userAlreadyVoted = true;
                    }

                        if (userAlreadyVoted === false && storedAnswersText && storedAnswersText.includes(parentInfo.data.currentLiveSession.id)) {
                            userAlreadyVoted = true;
                    }

                        if (!this.props.changedEventInfo || this.props.changedEventInfo.automaticJumpPoll === "Active") {
                            if (userAlreadyVoted === false) {
                                this.goToPollPage();
                            }
                    }
                }
            }

        });

        if (this.unsubscribeGetAllAgendaFilesOnChange) {
            this.unsubscribeGetAllAgendaFilesOnChange();
        }
        this.props.onGetAllAgendaFilesOnChange(this.state.eventId, this.state.roomId, this.state.agendaId, (agendaFilesList, unsubscribe) => {
            this.unsubscribeGetAllAgendaFilesOnChange = unsubscribe;
                
                let userStoredLanguage = Utility.getEventLanguage();

            agendaFilesList.sort(function (a, b) { return a.fileOrderIndex > b.fileOrderIndex ? -1 : 1 });
            var agendaFileExists = false;
            var agendaFile;
            for (var i = 0; i < agendaFilesList.length; i++) {
                    if (agendaFilesList[i].language === userStoredLanguage) {
                    agendaFileExists = true;
                    agendaFile = agendaFilesList[i];
                    break;
                }
            }

            if (agendaFileExists === false) {
                this.setState({
                    ...this.state,
                    currentAgendaFile: "NotExists",
                });
            } else {
                this.setState({
                    ...this.state,
                    agendaFilesList,
                    currentAgendaFile: agendaFile,
                    agendaLinkAddressToShow: agendaFile.fileUrl
                });
            }

        });


            //USER CONNECTION STATUS MANAGEMENT

            // Create a reference to this user's specific status node.
            // This is where we will store data about being online/offline.
            var userStatusDatabaseRef = firebase.database().ref('/status/' + participant.id);

            // We'll create two constants which we will write to
            // the Realtime database when this device is offline
            // or online.
            var isOfflineForDatabase = {
                state: 'offline',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };

            var isOnlineForDatabase = {
                state: 'online',
                last_changed: firebase.database.ServerValue.TIMESTAMP,
            };


            var conStat = this;
            // Create a reference to the special '.info/connected' path in
            // Realtime Database. This path returns `true` when connected
            // and `false` when disconnected.
            firebase.database().ref('.info/connected').on('value', function (snapshot) {
                // If we're not currently connected, don't do anything.

                if (snapshot.val() === false) {
                    if (conStat.state.event !== '') {
                        firestore.disableNetwork().then(() => {
                            // Do offline actions
                            // ...

                            conStat.setState({ userStatus: 'Offline' });
                        });
    }
                } else if (snapshot.val() === true) {
                    //We disable and enable the netwqork in the firebase so we will be sure that it will not use the catched data
                    //but will received the last data by calling the onEventInfoChange function

                    if (this.state.userStatus === 'Offline') {
                        firestore.enableNetwork().then(() => {
                            // Do online actions

                            conStat.setState({
                                userStatus: 'Online'
                            });
                        });
                    }

                    // If we are currently connected, then use the 'onDisconnect()' method to add a set which will only trigger once this
                    // client has disconnected by closing the app, losing internet, or any other means.
                    userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
                        // The promise returned from .onDisconnect().set() will resolve as soon as the server acknowledges the onDisconnect()
                        // request, NOT once we've actually disconnected: https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect
                        // We can now safely set ourselves as 'online' knowing that theserver will mark us as offline once we lose connection.
                        userStatusDatabaseRef.set(isOnlineForDatabase);
                    });

                }

            }, conStat);
        }
    }

    componentDidUpdate(prevProps) {

        var prevLangIt = "";
        var prevLangEn = "";
        var currLangIt = "";
        var currLangEn = "";

        if (this.props.changedEventInfo.availableLanguages) {
            currLangIt = this.props.changedEventInfo.availableLanguages.lang_it;
            currLangEn = this.props.changedEventInfo.availableLanguages.lang_en;
        }

        if (prevProps.changedEventInfo.availableLanguages) {
            prevLangIt = prevProps.changedEventInfo.availableLanguages.lang_it;
            prevLangEn = prevProps.changedEventInfo.availableLanguages.lang_en;
        }
        else {
            prevLangIt = currLangIt;
            prevLangEn = currLangEn;
        }

        if (prevLangIt != currLangIt || prevLangEn != currLangEn) {
            if (!(currLangIt && currLangEn)) {
                if (currLangIt) {
                    this.onSelectLanguage("Italian");
                }
                else if (currLangEn) {
                    this.onSelectLanguage("English");
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.unsubscribeGetListOfRootChildrenOnChange) {
            this.unsubscribeGetListOfRootChildrenOnChange();
        }

        if (this.unsubscribeGetAllAgendaFilesOnChange) {
            this.unsubscribeGetAllAgendaFilesOnChange();
        }
        
        if (this.unsubscribeGetEventRoomsOnChange) {
            this.unsubscribeGetEventRoomsOnChange();
        }

        if (this.unsubscribeGetParentInfoOnChange) {
            this.unsubscribeGetParentInfoOnChange();
        }

        if (this.unsubscribeEventInfoChange) {
            this.unsubscribeEventInfoChange();
        }
    }

    goToDashboardPage() {
        this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + this.state.roomId);
    }

    goToPollPage() {
        this.props.history.push("/event/" + this.state.eventId + "/dashboard/" + this.state.roomId + "/poll");
    }

    goRoomPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/rooms");
    }

    goLibraryPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library")
    }

    goToQuestionPage() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/question");
    }

    goIntroduction() {
        this.props.history.push("/event/" + this.props.changedEventInfo.id + "/introduction");
    }

    goLogInPage() {
        this.props.history.push("/");
    }

    goToRootChild(item, type) {

        var fileExists = true;
        if (type === "agenda") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/agenda/" + item.id);
            this.setState({ ...this.state, agendaId: item.id });
            if (this.unsubscribeGetAllAgendaFilesOnChange) {
                this.unsubscribeGetAllAgendaFilesOnChange();
            }
            this.props.onGetAllAgendaFilesOnChange(this.state.eventId, this.state.roomId, item.id, (agendaFilesList, unsubscribe) => {
                this.unsubscribeGetAllAgendaFilesOnChange = unsubscribe;
                agendaFilesList.sort(function (a, b) { return a.fileOrderIndex > b.fileOrderIndex ? -1 : 1 });
                var agendaFileExists = false;
                var agendaFile;
                let userStoredLanguage = Utility.getEventLanguage();
                for (var i = 0; i < agendaFilesList.length; i++) {
                    if (agendaFilesList[i].language === userStoredLanguage) {
                        agendaFileExists = true;
                        agendaFile = agendaFilesList[i];
                        break;
                    }
                }

                if (agendaFileExists === false) {
                    this.setState({
                        ...this.state,
                        currentAgendaFile: "NotExists",
                    });
                } else {
                    this.setState({
                        ...this.state,
                        agendaFilesList,
                        currentAgendaFile: agendaFile,
                        agendaLinkAddressToShow: agendaFile.fileUrl
                    });
                }

            });
        } else if (type === "survey") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + item.id);
        } else if (type === "qrcode") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/qrcode/" + item.id);
        } else if (type === "library") {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/library/" + item.id);
        }
    }

    onSelectLanguage(option) {
        var value = option;
        this.props.onUpdateParticipantData(this.state.participantInfo, this.state.participantInfo.eventId, 'changedLanguage', value);
        Utility.setEventLanguage(option);

        var agendaFileExists = false;
        let agendaFile;
        let agendaFilesList;

        if (this.unsubscribeGetAllAgendaFilesOnChange) {
            this.unsubscribeGetAllAgendaFilesOnChange();
        }        
        this.props.onGetAllAgendaFilesOnChange(this.state.eventId, this.state.roomId, this.state.agendaId, (agendaFilesList, unsubscribe) => {
            this.unsubscribeGetAllAgendaFilesOnChange = unsubscribe;
            agendaFilesList.sort(function (a, b) { return a.fileOrderIndex > b.fileOrderIndex ? -1 : 1 });
            for (var i = 0; i < agendaFilesList.length; i++) {
                if (agendaFilesList[i].language === option) {
                    agendaFileExists = true;
                    agendaFile = agendaFilesList[i];
                    agendaFilesList = agendaFilesList;
                    break;
                }
            }
            if (agendaFileExists === false) {
                this.setState({
                    ...this.state,
                    currentAgendaFile: "NotExists",
                    sidebarOpen: false,
                });
            } else {
                this.setState({
                    ...this.state,
                    agendaLinkAddressToShow: agendaFile.fileUrl,
                    currentAgendaFile: agendaFile,
                    sidebarOpen: false,
                    agendaFilesList: agendaFilesList
                });
            }
        });

    }

    getUserPreference(item) {
        this.setState({
            ...this.state,
            currentAgendaFile: item,
        });
    }

    goToSurveyPageModal(survey) {

        var surveyAlerts = JSON.parse(localStorage.getItem("surveyAlerts"));
        if (!surveyAlerts) {
            var surveyAlertsNew = [];
            surveyAlertsNew.push(survey.id);
            localStorage.setItem("surveyAlerts", JSON.stringify(surveyAlertsNew));
        } else {
            surveyAlerts[surveyAlerts.length] = survey.id;
            localStorage.setItem("surveyAlerts", JSON.stringify(surveyAlerts));
        }

        //this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + surveyid);

        if (survey.startInIframe) {
            this.props.history.push("/event/" + this.props.changedEventInfo.id + "/dashboard/" + this.state.parentInfo.id + "/survey/" + survey.id);
        }
        else {
            this.props.onGetSurveyFilesData(this.props.changedEventInfo.id, survey.id, (surveyfiles) => {
                window.open(surveyfiles[0].fileUrl, "_blank");
            });
        } 
    }

    render() {

        var headerComponent;
        var borderType;
        var selectedLanguageStrings;

        let userStoredLanguage = Utility.getEventLanguage();
        if (userStoredLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
        } else {
            selectedLanguageStrings = italianStrings;
        }


        if (this.state.agendaFilesList.length > 0) {
            var itemNumbers = 0;
            var agendaFilesComponent = this.state.agendaFilesList.map(item => {

                if (item.language === userStoredLanguage) {
                    itemNumbers++;
                    return (
                        <div className="custom-control custom-radio" onClick={() => { this.getUserPreference(item) }}>
                            {(item.id === this.state.currentAgendaFile.id) ? (
                                <input type="radio" id={"agendaFileItem-" + item.id} name="agendaFileItem" className="custom-control-input" checked />
                            ) : (
                                <input type="radio" id={"agendaFileItem-" + item.id} name="agendaFileItem" className="custom-control-input" />
                            )}
                            <label className="agendaLabel custom-control-label radio-cicle-label-agenda" htmlFor={"agendaFileItem-" + item.id}>{item.name}</label>
                        </div>
                    )
                }
            })
        }

        if (this.props.changedEventInfo && this.props.changedEventInfo.length !== 0) {
            /************** Question List on Start Up **********/
            headerComponent = (<div className="">
                <Header
                    idEvent={this.state.eventId}
                    event={this.state.event}
                    userStatus={this.state.userStatus}
                    onSetSidebarOpen={this.onSetSidebarOpen}
                    parentInfo={this.state.parentInfo}
                    roomsList={this.state.roomsList}
                    roomId={this.state.roomId}
                    goIntroduction={this.goIntroduction.bind(this)}
                    goLogInPage={this.goLogInPage.bind(this)}
                    goRoomPage={this.goRoomPage.bind(this)}
                    goLibraryPage={this.goLibraryPage.bind(this)}
                    goToDashboardPage={this.goToDashboardPage.bind(this)}
                    goToQuestionPage={this.goToQuestionPage.bind(this)}
                    goToSurveyPageModal={this.goToSurveyPageModal.bind(this)}
                    goToRootChild={this.goToRootChild.bind(this)}
                    onSelectLanguage={this.onSelectLanguage.bind(this)}
                    pageName={"AgendaScreen"}
                    agendaId={this.state.agendaId}
                    rootChildrenList={this.state.rootChildrenList}
                />

            </div>);

            if (this.state.eventType === 'questions') {
                borderType = 'border-bottom-question';
            } else if (this.state.eventType === 'tweets') {
                borderType = 'border-bottom-tweet';
            } else {
                borderType = "";
            }
        }
        var imageTypes = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png"]

        let containerscreenclass = "";
        let backgroundimagestyle = "";
        if (this.state.event.themeParticipantImageUrl === "default") {
            containerscreenclass = "container-screen-default-agenda";
            backgroundimagestyle ="";
        } else {
            containerscreenclass = "container-screen";
            if (this.state.event.themeParticipantImageUrl == undefined) {
                backgroundimagestyle = "";
            }
            else {
                backgroundimagestyle = `url(" ${this.state.event.themeParticipantImageUrl} ")`;
            }
        }
                
        return (
            <main>
                <div>
                    {headerComponent}
                </div>
                <div className="imageTheme" style={{ backgroundImage: backgroundimagestyle }}>
                    <div className={`container ${containerscreenclass}`}>
                        {(this.state.currentAgendaFile === "NotExists") ? (
                            null
                            /* CARD TO SHOW "NO AGENDA LOADED". BETTER TO NOT SHOW IT BECAUSE IT IS SHOWN BEFORE THE AGENDA IS LOADED.
                            <div className="container container-screen-iframe">
                                <div className="card questionCard  m-2" id="card-box" >
                                    <div className="card-header" style={{ backgroundColor: this.props.changedEventInfo.primaryColor }}>
                                        <h4 className="questionHeader" style={{ color: this.props.changedEventInfo.textColor }}> &nbsp; </h4>
                                    </div>
                                    <div className="card-body black-text" id="cardOptionContent">
                                        <div className="row">
                                            <div className="col-12 text-center" style={{ color: this.props.changedEventInfo.primaryColor }}>
                                                <h5>{selectedLanguageStrings.no_agenda_language}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            */
                        ) : (
                            (itemNumbers === 1) ? (
                                <div className="container container-screen-iframe">
                                    {(imageTypes.includes(this.state.currentAgendaFile.type)) ? (
                                        <img className="imageAgenda" src={this.state.currentAgendaFile.fileUrl}></img>
                                    ) : (
                                        <iframe id="serviceFrameSend" src={this.state.currentAgendaFile.fileUrl} frameBorder="0" />
                                    )}
                                </div>
                            ) : (
                                <div className="container container-screen-iframe">
                                    <div className="m-2">
                                        {agendaFilesComponent}
                                    </div>
                                    {(imageTypes.includes(this.state.currentAgendaFile.type)) ? (
                                        <img className="imageAgenda" src={this.state.currentAgendaFile.fileUrl}></img>
                                    ) : (
                                        <iframe id="serviceFrameSend" src={this.state.currentAgendaFile.fileUrl} frameBorder="0" />
                                    )}
                                </div>
                            )
                        )}
                    </div>
                </div>
            </main>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onEventInfoChange: (eventId, callback) => dispatch(generalActions.getEventInfoOnChange(eventId, callback)),
        onGetParentInfoOnChange: (eventId, selectedRoomId, callback) => dispatch(generalActions.getParentInfoOnChange(eventId, selectedRoomId, callback)),
        onUpdateParticipantData: (participantId, eventId, option, value) => dispatch(generalActions.updateParticipantData(participantId, eventId, option, value)),
        onGetEventRoomsOnChange: (eventId, callback) => dispatch(generalActions.getEventRoomsOnChange(eventId, callback)),
        onGetAllAgendaFilesOnChange: (eventId, roomId, agendaId, callback) => dispatch(generalActions.getAllAgendaFilesOnChange(eventId, roomId, agendaId, callback)),
        onGetListOfRootChildrenOnChange: (eventId, callback) => dispatch(generalActions.getListOfRootChildrenOnChange(eventId, callback)),
        onGetSurveyFilesData: (eventId, surveyId, callback) => dispatch(generalActions.getSurveyFilesData(eventId, surveyId, callback)),
        onGetSurveyFiles: (eventId, surveyId, callback) => dispatch(generalActions.getSurveyFiles(eventId, surveyId, callback))
    };
}

function mapStateToProps(state) {
    return {
        changedEventInfo: state.generalReducers.changedEventInfo,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgendaScreenRoot);