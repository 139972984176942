import React, { Component } from 'react';
import { connect } from 'react-redux';
import { generalActions } from '../Redux/Actions/generalActions';
import Sidebar from "react-sidebar";
import { englishStrings } from '../Languages/en_EN';
import { italianStrings } from '../Languages/it_IT';
import Flag from 'react-world-flags';
import MessageModal from '../Component/MessageModal';
import Utility from '../Helpers/Utility';
import $ from "jquery";
import 'bootstrap';

class Header extends Component {

    constructor(props) {
        super(props);
        // this.idEvent = this.props.match.params.idevent;
        this.state = {
            eventTitle: '',
            eventCode: '',
            participantInfo: [],
            event: [],
            headerStatus: false,
            sidebarOpen: false,
            toggleActive: false,
            textToggle: 'OFF',
            componentMounted: false,
            titleFontSize: '',
            badgeNumber: 0,
            eventMessageList: [],
            eventMessagesModal: "eventMessagesModal",
            unreadImportantMessages: [],
            eventImportantModal: "eventImportantModal",
            pushedEventMessageList: [],
            pushedEventMessageShow: '',
            surveyUrls: {},
            currentsurveyInfo: "default",
            showSurveyAlert: false,
            mainMenuVisible: true,
            mainMenuVisibleClass: 'titleHeader',
            elementHeaderVisibleClass: 'titleHeader',
            headerVisible: true,
            headerVisibleClass: 'nav-bar',
        };

        this.saveUserInfo = this.saveUserInfo.bind(this);
        this.getUserData = this.getUserData.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
        this.getEventMessages = this.getEventMessages.bind(this);
        this.onSelectLanguage = this.onSelectLanguage.bind(this);
        this.goToRootChildState = this.goToRootChildState.bind(this);

    }

    componentDidMount() {
        let thiscopy = this;

        $('#pushmessageModal').on('hidden.bs.modal', function () {
            thiscopy.setSeenMessage();
            thiscopy.setBadgeNumber();
        });

        let participant = JSON.parse(localStorage.getItem('participant-' + this.props.changedEventInfo.id));
        let userStoredLanguage = Utility.getEventLanguage();
        var titleFont = '14px';
        var titleText = this.props.changedEventInfo.title;
        if (this.props.changedEventInfo.length !== 0 && this.props.changedEventInfo.title.length >= 18 && this.props.changedEventInfo.title.length < 22) {
            //2 lines of text so we need also to add some distance between the title and the navbar 
            titleFont = "13px";
        } else if (this.props.changedEventInfo.length !== 0 && this.props.changedEventInfo.title.length >= 22 && this.props.changedEventInfo.title.length < 30) {
            titleFont = "12px";
        } else if (this.props.changedEventInfo.length !== 0 && this.props.changedEventInfo.title.length >= 30) {
            //we replace the extra characters with ... to better fit on the header
            titleFont = '14px';
            var titleShortened = titleText.slice(0, 30);
            titleText = titleShortened + " ..."
        }
        this.setState({
            ...this.state,
            // eventTitle: callback.title,
            titleFontSize: titleFont,
            eventCode: this.props.changedEventInfo.eventCode,
            participantInfo: participant,
            componentMounted: true,
        });

        if (this.props.serverHeartBeat == null) {
            this.props.onServerHeartBeatOnChange(this.props.changedEventInfo.id, (callback, unsubscribe) => {});
        }


        if (this.unsubscribeEventInfoChange) {
            this.unsubscribeEventInfoChange();
        }

        this.props.onEventInfoChange(this.props.changedEventInfo.id, (callback, unsubscribe) => {
            this.unsubscribeEventInfoChange = unsubscribe;

            let popupeventshow = false;
            if (callback.data.currentSurveyElement !== "default") {
                let surveyAlerts = JSON.parse(localStorage.getItem("surveyAlerts"));
                if (!surveyAlerts || !surveyAlerts.includes(callback.data.currentSurveyElement)) {
                    popupeventshow = true;
                }
            }

            if (popupeventshow) {
                this.popupSurvey(callback.data.currentSurveyElement);
            }
            else {
                if (this.unsubscribeGetEventRoomsOnChange) {
                    this.unsubscribeGetEventRoomsOnChange();
                }

                this.props.onGetEventRoomsOnChange(this.props.changedEventInfo.id, (roomsList, unsubscribe) => {
                    this.unsubscribeGetEventRoomsOnChange = unsubscribe;

                    let currentRoomSurvey = roomsList.find(room => room.id === this.props.roomId).currentSurveyElement;
                    if (currentRoomSurvey !== 'default') {
                        let surveyAlerts = JSON.parse(localStorage.getItem("surveyAlerts"));
                        if (!surveyAlerts || !surveyAlerts.includes(currentRoomSurvey)) {
                            this.popupSurvey(currentRoomSurvey);
                        }
                    }
                });
            }
        });

        if (this.unsubscribeGetAllEventMessages) {
            this.unsubscribeGetAllEventMessages();
        }

        this.props.onGetAllEventMessages(this.props.changedEventInfo.id, (eventMessageList, unsubscribe) => {
            this.unsubscribeGetAllEventMessages = unsubscribe;
            //Push messages ordered by older to newer to be showed in order of arrival in popup message.
            var pushedEventMessageList = [];
            for (var i = eventMessageList.length - 1; i >= 0; i--) {
                if (eventMessageList[i].pushed) {
                    pushedEventMessageList.push(eventMessageList[i]);
                }
            }

            var unreadImportantMessages = [];

            for (var i = 0; i < eventMessageList.length; i++) {
                if (eventMessageList[i].messageImportance === "high") {
                    unreadImportantMessages.push(eventMessageList[i]);
                }
            }

            if (unreadImportantMessages.length > 0) {
                this.setState({
                    ...this.state,
                    eventMessageList,
                    pushedEventMessageList,
                    unreadImportantMessages,
                    eventImportantModal: ""
                }, () => {
                    this.setBadgeNumber();
                    this.pushMessageShow();
                });
            } else {
                this.setState({
                    ...this.state,
                    eventMessageList,
                    pushedEventMessageList,
                }, () => {
                    this.setBadgeNumber();
                    this.pushMessageShow();
                });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.changedEventInfo.mainMenu !== undefined) {
            if (this.props.changedEventInfo.mainMenu.visible !== this.state.mainMenuVisible) {
                this.setMainMenuVisibility(this.props.changedEventInfo.mainMenu.visible);
            }
        }

        if (this.props.changedEventInfo.showHeaderBar !== undefined) {
            let headerVisible = (this.props.changedEventInfo.showHeaderBar === "Active") ? true : false;
            if (headerVisible !== this.state.headerVisible) {
                this.setHeaderVisibility(headerVisible);
            }
        }

    }

    componentWillUnmount() {

        if (this.unsubscribeEventInfoChange) {
            this.unsubscribeEventInfoChange();
        }

        if (this.unsubscribeGetEventRoomsOnChange) {
            this.unsubscribeGetEventRoomsOnChange();
        }

        if (this.unsubscribeGetSurveyInfoOnChange) {
            this.unsubscribeGetSurveyInfoOnChange();
        }

        if (this.unsubscribeGetAllEventMessages) {
            this.unsubscribeGetAllEventMessages();
        }

        if (this.unsubscribeGetSurveyFiles) {
            this.unsubscribeGetSurveyFiles();
        }
    }

    setBadgeNumber() {

        let participant = JSON.parse(localStorage.getItem('participant-' + this.props.changedEventInfo.id));

        if (participant && participant.VisitedMessages) {
            let badgenumber = 0;
            this.state.eventMessageList.forEach(function (eventmessage) {
                if (!participant.VisitedMessages.includes(eventmessage.id)) {
                    badgenumber++;
                }
            });
            this.setState({
                ...this.state,
                badgeNumber: badgenumber,
            });
        } else {
            this.setState({
                ...this.state,
                badgeNumber: this.state.eventMessageList.length,
            });
        }
    }

    saveUserInfo() {
        var name = document.getElementById("participanName").value;
        var email = document.getElementById("participantEmail").value;
        var info = { name: name, email: email };
        this.props.onUpdateParticipantData(this.state.participantInfo, this.props.changedEventInfo.id, 'userInfo', info);
    }

    getUserData() {
        let participant = JSON.parse(localStorage.getItem('participant-' + this.props.changedEventInfo.id));
        this.setState({ ...this.state, participantInfo: participant });
    }

    refreshPage() {
        window.location.reload();
    }

    setMainMenuVisibility(visibility) {
        let sidebarOpenVisibility;
        if (!visibility) {
            sidebarOpenVisibility = false;
        } else {
            sidebarOpenVisibility = this.state.sidebarOpen;
        }

        this.setState({
            ...this.state,
            mainMenuVisible: visibility,
            mainMenuVisibleClass: (visibility) ? "titleHeader" : "titleHeader hidden",
            sidebarOpen: sidebarOpenVisibility
        });
    }

    setHeaderVisibility(visibility) {
        let sidebarOpenVisibility;
        if (!visibility) {
            sidebarOpenVisibility = false;
        } else {
            sidebarOpenVisibility = this.state.sidebarOpen;
        }

        this.setState({
            ...this.state,
            headerVisible: visibility,
            headerVisibleClass: (visibility) ? "nav-bar" : "nav-bar hidden",
            elementHeaderVisibleClass: (visibility) ? "titleHeader" : "titleHeader hidden",
            sidebarOpen: sidebarOpenVisibility
        });
    }

    onSetSidebarOpen() {
        if (this.state.sidebarOpen === false) {
            this.setState({ ...this.state, sidebarOpen: true });

        } else {
            this.setState({ ...this.state, sidebarOpen: false });
        }
    }

    goToRootChildState(item, type) {
        if (this.state.sidebarOpen === false) {
            this.setState({ ...this.state, sidebarOpen: true });

        } else {
            this.setState({ ...this.state, sidebarOpen: false });
        }

        this.props.goToRootChild(item, type);
    }

    onSelectLanguage(option) {
        this.setState({
            ...this.state,
            sidebarOpen: false
        });
        this.props.onSelectLanguage(option);
    }


    //getEventMessages() {
    //     this.setState({ ...this.state, sidebarOpen: false });
    //     this.props.getEventMessages();

    // }

    getEventMessages() {
        // each time that the user clicks on the get event list we set the badge number visited to the current badgeNumber
        // which is the length of normal messages received
        this.setState({
            ...this.state,
            eventMessagesModal: "eventMessagesModal",
            sidebarOpen: false
        });

        //we store the badgeNumber visited on the local storage so on the refresh of the page we have access to the last number of visited event messages

        /*
        let participant = JSON.parse(localStorage.getItem('participant-' + this.props.changedEventInfo.id));
        Object.assign(participant, { 'VisitedMessages': this.state.eventMessageList.length });
        localStorage.setItem('participant-' + this.props.changedEventInfo.id, JSON.stringify(participant));
        */
    }

    setSeenMessage() {
        let arrayMessagesSeen = [];
        let jsonMessages = JSON.parse(localStorage.getItem("seenMessages"));
        if (jsonMessages) {
            arrayMessagesSeen = jsonMessages;
        }
        arrayMessagesSeen.push(this.state.pushedEventMessageShow.id);
        localStorage.setItem("seenMessages", JSON.stringify(arrayMessagesSeen));

        this.pushMessageShow();

    }

    pushMessageShow() {
        //Check for messages pending to be pushed.
        let seenMessages = JSON.parse(localStorage.getItem("seenMessages"));
        let thiscopy = this;
        if (!($("#pushmessageModal").data('bs.modal') || {})._isShown) {
            if (this.state.pushedEventMessageList.length > 0) {
                if (seenMessages) {
                    this.state.pushedEventMessageList.every(function (eventmessage) {
                        if (!seenMessages.includes(eventmessage.id)) {
                            thiscopy.setState({
                                ...thiscopy.state,
                                pushedEventMessageShow: eventmessage
                            }, () => {
                                $('#pushmessageModal').modal('show');
                            });
                            return false;
                        }
                        return true;
                    });
                } else {
                    this.setState({
                        ...this.state,
                        pushedEventMessageShow: this.state.pushedEventMessageList[0]
                    }, () => {
                        $('#pushmessageModal').modal('show');
                    });
                }
            }
        }
    }

    popupSurvey(currentRoomSurvey) {
        if (this.unsubscribeGetSurveyInfoOnChange) {
            this.unsubscribeGetSurveyInfoOnChange();
        }
        this.props.onGetSurveyInfoOnChange(this.props.changedEventInfo.id, currentRoomSurvey, (surveyInfo, unsubscribe) => {
            this.unsubscribeGetSurveyInfoOnChange = unsubscribe;
            const userStoredLanguage = Utility.getEventLanguage();
            if (surveyInfo && surveyInfo.surveyStatus === "live" && (surveyInfo.language === userStoredLanguage || surveyInfo.language === "All")) {
                this.setState({
                    ...this.state,
                    currentsurveyInfo: surveyInfo,
                    showSurveyAlert: true
                });
                document.getElementById("surveyModalBtn").click();
            } else {
                //FC Patch ToBeFixed
                if (document.getElementById("close-modal-survey")) {
                    document.getElementById("close-modal-survey").click();
                }
            }
        });
    }

    render() {

        var selectedLanguageStrings;
        var selectedLanguageCustomStrings;
        let userStoredLanguage = Utility.getEventLanguage();

        var iconColorNotSelected = this.props.changedEventInfo.headerBackgroundColor;
        var iconColorSelected = this.props.changedEventInfo.headerTextColor;

        let counterServerHeartBeatClass = "";
        if (this.props.serverHeartBeat) {
            if (this.props.serverHeartBeat.counter > 0) {
            if (this.props.serverHeartBeat.counter % 2 === 0) {
                counterServerHeartBeatClass = "even";
            }
            else {
                counterServerHeartBeatClass = "odd";
            }
        }
            else {
                counterServerHeartBeatClass = "";
            }
        }


        if (userStoredLanguage === 'English') {
            selectedLanguageStrings = englishStrings;
            if (this.props.changedEventInfo.customLabels) {
                selectedLanguageCustomStrings = this.props.changedEventInfo.customLabels.lang_en;
            }
        } else {
            selectedLanguageStrings = italianStrings;
            if (this.props.changedEventInfo.customLabels) {
                selectedLanguageCustomStrings = this.props.changedEventInfo.customLabels.lang_it;
            }
        }

        if (this.props.rootChildrenList && this.props.rootChildrenList.length > 0) {
            var rootChildrenComponent = this.props.rootChildrenList.map((item, index) => {
                if (item.sType === "agenda" && (userStoredLanguage === item.language || item.language == "All")) {
                    return (
                        <div className="col-12 menuItem" key={index}
                            style={{ borderBottomColor: this.props.changedEventInfo.headerBackgroundColor }}>
                            <div className="row" onClick={() => {
                                this.goToRootChildState(item, "agenda")
                            }}>
                                {(this.props.agendaId === item.id) ? (
                                    <button className="col-12 text-left p-2" type="button" style={{
                                        backgroundColor: this.props.changedEventInfo.headerBackgroundColor,
                                        color: this.props.changedEventInfo.headerTextColor
                                    }}>
                                        <i className="fas fa-calendar-alt text-warning menuIcons"></i>
                                        <label className="pl-2">{item.title}</label>
                                    </button>
                                ) : (
                                    <button className="col-12 text-left p-2 bg-white" type="button">
                                        <i style={{ color: `${iconColorNotSelected}` }} className="fas fa-calendar-alt bg-white menuIcons"></i>
                                        <label className="text-grey pl-2 bg-white">{item.title}</label>
                                    </button>
                                )}
                            </div>
                        </div>
                    )
                } else if (item.sType === "library" && item.showLibraryInMenu === true) {
                    return (
                        <div className="col-12 menuItem" key={index}
                            style={{ borderBottomColor: this.props.changedEventInfo.headerBackgroundColor }}>
                            <div className="row" onClick={() => {
                                this.goToRootChildState(item, "library")
                            }}>

                                {(this.props.libraryId === item.id) ? (
                                    <button className="col-12 text-left p-2" type="button" style={{
                                        backgroundColor: this.props.changedEventInfo.headerBackgroundColor,
                                        color: this.props.changedEventInfo.headerTextColor
                                    }}>
                                        <i className="fas fa-calendar-alt menuIcons"></i>
                                        <label className="pl-2">{item.title}</label>
                                    </button>
                                ) : (
                                <button className="col-12 text-left p-2 bg-white" type="button">
                                        <i style={{ color: `${iconColorNotSelected}` }} className="fas fa-book-open bg-white menuIcons"></i>
                                    <label className="text-grey pl-2 bg-white">{item.title}</label>
                                </button>
                                )}
                            </div>
                        </div>
                    )
                } else if (item.sType === 'survey' && item.showSurveyInMenu === true && (item.sParentId === "default" || item.sParentId === this.props.parentInfo.id) && (userStoredLanguage === item.language || item.language == "All")) {

                    return (
                        <div className="col-12 menuItem" key={index}
                            style={{ borderBottomColor: this.props.changedEventInfo.headerBackgroundColor }}>
                            {item.startInIframe ? (
                                <div className="row" onClick={() => this.goToRootChildState(item, 'survey')}>
                                    {(this.props.surveyId === item.id) ? (
                                        <button className="col-12 text-left p-2" type="button" style={{
                                            backgroundColor: this.props.changedEventInfo.headerBackgroundColor,
                                            color: this.props.changedEventInfo.headerTextColor
                                        }}>
                                            <i className="fas fa-list-alt menuIcons"></i>
                                            <label className="pl-2">{item.title}</label>
                                        </button>
                                    ) : (
                                        <button className="col-12 text-left p-2 bg-white" type="button">
                                            <i style={{ color: `${iconColorNotSelected}` }} className="fas fa-list-alt bg-white menuIcons"></i>
                                            <label className="text-grey pl-2 bg-white">{item.title}</label>
                                        </button>
                                    )}
                                </div>
                            ) : (
                                <a href={item.surveyLinkUrl} target="_blank">
                                    <div className="row">
                                        {(this.props.surveyId === item.id) ? (
                                            <button className="col-12 text-left p-2" type="button" style={{
                                                backgroundColor: this.props.changedEventInfo.headerBackgroundColor,
                                                color: this.props.changedEventInfo.headerTextColor
                                            }}>
                                                <i className="fas fa-list-alt menuIcons"></i>
                                                <label className="pl-2">{item.title}</label>
                                            </button>
                                        ) : (
                                            <button className="col-12 text-left p-2 bg-white" type="button">
                                                <i className="fas fa-list-alt text-info bg-white menuIcons"></i>
                                                <label className="text-grey pl-2 bg-white">{item.title}</label>
                                            </button>
                                        )}
                                    </div>
                                </a>
                            )}

                        </div>
                    )
                } else if (item.sType === "qrcode" && item.showQRCodeInMenu === true && (item.sParentId === "default" || item.sParentId === this.props.parentInfo.id)) {
                    return (
                        <div className="col-12 menuItem" key={index}
                            style={{ borderBottomColor: this.props.changedEventInfo.headerBackgroundColor }}>
                            <div className="row" onClick={() => {
                                this.goToRootChildState(item, "qrcode")
                            }}>
                                {/*(this.props.surveyId === item.id)?(
                                    <button className="col-12 text-left p-2" type="button" style={{ backgroundColor: this.props.changedEventInfo.headerBackgroundColor, color: this.props.changedEventInfo.headerTextColor }}>
                                        <i className="fas fa-qrcode menuIcons"></i>
                                        <label className="pl-2">{item.title}</label>
                                    </button>
                                ):(*/
                                    <button className="col-12 text-left p-2 bg-white" type="button">
                                        <i className="fas fa-qrcode bg-white menuIcons"></i>
                                        <label className="text-grey pl-2 bg-white">{item.title}</label>
                                    </button>
                                    /* ) */}
                            </div>
                        </div>
                    )
                }
            })
        }

        return (
            <div>
                <nav className={`${this.state.headerVisibleClass} py-2`}
                    style={{ backgroundColor: this.props.changedEventInfo.headerBackgroundColor }} id="headerNav">
                    <div className="container">
                        <form id="formHeader">
                            {(this.props.changedEventInfo.eventLogoUrl && this.props.changedEventInfo.title) ? (
                                <div>
                                    <div className="form-row">
                                        {/* Main Menu burger icon */}
                                        <div className={`col-2 text-left ${this.state.mainMenuVisibleClass}`}
                                            onClick={() => this.onSetSidebarOpen()}>
                                            <label><i className="fas fa-bars"
                                                style={{ color: this.props.changedEventInfo.headerTextColor }}></i>
                                                {(this.state.badgeNumber > 0) ? (
                                                    <i className="fas fa-circle text-danger badgeSettingIcon"></i>
                                                ) : (
                                                    <span></span>
                                                )}
                                            </label>
                                        </div>
                                        {/* Event Logo and Title */}
                                        <div className={`col-8 mb-2 ${this.state.elementHeaderVisibleClass}`}>
                                            {(this.props.changedEventInfo.showEventInfoStatus === "Active") ? (
                                                (this.props.changedEventInfo.eventLogoUrl !== "default") ? (

                                                    <div className="d-flex justify-content-center logoHeader pt-1">
                                                        <img src={this.props.changedEventInfo.eventLogoUrl} alt="Logo" />
                                                        <label className="" style={{
                                                            color: this.props.changedEventInfo.headerTextColor,
                                                            fontSize: this.state.titleFontSize,
                                                            paddingTop: "10px"
                                                        }}>
                                                            <b>{this.props.changedEventInfo.title}
                                                                {/* <br/>
                                                                #{this.props.changedEventInfo.eventCode} */}
                                                            </b>
                                                        </label>
                                                        <title>{this.props.changedEventInfo.title}</title>

                                                    </div>
                                                ) : (
                                                    <div className="d-flex justify-content-center logoHeader">
                                                        {/* <img src={"/files/LogoTemp.png"} alt="Logo" /> */}
                                                        <label style={{
                                                            color: this.props.changedEventInfo.headerTextColor,
                                                            fontSize: this.state.titleFontSize,
                                                            paddingTop: "5px"
                                                        }}>
                                                            <b>{this.props.changedEventInfo.title}</b>
                                                        </label>
                                                        <title>{this.props.changedEventInfo.title}</title>
                                                    </div>
                                                )

                                            ) : (
                                                (this.props.changedEventInfo.eventLogoUrl !== "default") ? (
                                                    <div className="d-flex justify-content-center logoHeader2">
                                                        {/* <img src={this.props.changedEventInfo.eventLogoUrl} className='img-fluid img-thumbnail' alt="Logo" /> */}
                                                        <img src={this.props.changedEventInfo.eventLogoUrl} alt="Logo" />

                                                    </div>
                                                ) : (
                                                    // <div className="d-flex justify-content-center logoHeader2">
                                                    //     <img src={"/files/LogoTemp.png"} alt="Logo" />
                                                    // </div>
                                                    <div></div>
                                                )
                                            )}
                                        </div>
                                        {/* Participant Profile */}
                                        <div className={`col-1 text-right ${this.state.elementHeaderVisibleClass}`}>
                                            {(this.props.changedEventInfo.showProfileToParticipants === "Yes") ? (
                                                <div
                                                    style={{ backgroundColor: this.props.changedEventInfo.headerBackgroundColor }}
                                                    data-toggle="modal" data-target="#profileModal"
                                                    onClick={this.getUserData}>
                                                    <i className="far fa-user-circle"
                                                        style={{ color: this.props.changedEventInfo.headerTextColor }}></i>
                                                </div>
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                        {/* Connection Status */}
                                        <div className="col-1 text-right titleHeader">
                                            {(this.props.userStatus === 'Offline') ? (
                                                <div style={{
                                                    backgroundColor: 'transparent',
                                                    color: this.props.changedEventInfo.headerTextColor
                                                }} onClick={this.refreshPage}>
                                                    <i className="fas fa-wifi text-danger"></i>
                                                </div>
                                            ) : (
                                                <div style={{
                                                    backgroundColor: 'transparent',
                                                    color: this.props.changedEventInfo.headerTextColor
                                                }} onClick={this.refreshPage}>
                                                    <i className="fas fa-wifi "></i>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </form>
                    </div>
                    {(this.state.sidebarOpen === true) ? (
                        <Sidebar
                            sidebar={
                                <div className="settingContainer">
                                    <div className="col-12 headerSetting " style={{
                                        backgroundColor: this.props.changedEventInfo.headerBackgroundColor,
                                        color: this.props.changedEventInfo.headerTextColor
                                    }}>
                                    </div>
                                    <div className="col-12 p-3" style={{
                                        backgroundColor: this.props.changedEventInfo.headerBackgroundColor,
                                        color: this.props.changedEventInfo.headerTextColor
                                    }}>
                                        <h5>{selectedLanguageStrings.main_menu}</h5>
                                    </div>
                                    {this.props.changedEventInfo.mainMenu === undefined || this.props.changedEventInfo.mainMenu.intro ? (
                                        <div className="col-12 menuItem"
                                            style={{ borderBottomColor: this.props.changedEventInfo.headerBackgroundColor }}>
                                            <div className="row">
                                                {(this.props.changedEventInfo.splashImageUrl === "default") ? (
                                                    <div></div>
                                                ) : (
                                                    <button className="col-12 text-left p-2 bg-white" type="button p-2"
                                                        onClick={this.props.goIntroduction}>
                                                        <i style={{ color: `${iconColorNotSelected}` }} className="fas fa-info bg-white menuIconsHome"></i>
                                                        <label
                                                            className="text-grey pl-2 bg-white">Intro</label>
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    ) : <div></div>}

                                    {(this.props.changedEventInfo.mainMenu === undefined || this.props.changedEventInfo.mainMenu.home) ? (
                                        (this.props.pageName === "DashboardScreen") ? (
                                            <div className="col-12 menuItem"
                                                style={{ borderBottomColor: this.props.changedEventInfo.headerBackgroundColor }}>
                                                <div className="row">
                                                    <button className="col-12 text-left p-2 " type="button" style={{
                                                        backgroundColor: this.props.changedEventInfo.headerBackgroundColor,
                                                        color: this.props.changedEventInfo.headerTextColor
                                                    }}>
                                                        <i className="fas fa-home menuIcons"></i>
                                                        <label
                                                            className="pl-2">{selectedLanguageStrings.home}</label>
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="col-12 menuItem"
                                                style={{ borderBottomColor: this.props.changedEventInfo.headerBackgroundColor }}>
                                                <div className="row" onClick={this.props.goToDashboardPage}>
                                                    <button className="col-12 text-left p-2 bg-white" type="button">
                                                        <i style={{ color: `${iconColorNotSelected}` }} className="fas fa-home bg-white menuIcons"></i>
                                                        <label
                                                            className="text-grey pl-2 bg-white">{selectedLanguageStrings.home}</label>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        <div></div>
                                    )}

                                    {(this.props.changedEventInfo.availableLanguages.lang_en === true && this.props.changedEventInfo.availableLanguages.lang_it === true) ? (
                                        <div className="col-12 menuItem"
                                            style={{ borderBottomColor: this.props.changedEventInfo.headerBackgroundColor }}>
                                            <div className="row">
                                                <div className="col-12 btn-group dropright text-left  p-2 bg-white ">
                                                    <i style={{ color: `${iconColorNotSelected}` }} className="fas fa-globe-africa bg-white menuIcons"></i>
                                                    <label className="dropdown-toggle bg-white pl-2"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.select_language : selectedLanguageStrings.select_language}&nbsp; &nbsp;
                                                    </label>
                                                    <div className="dropdown-menu">
                                                        {
                                                            (userStoredLanguage === 'English') ? (
                                                                <button className="dropdown-item p-2" type="button"
                                                                    onClick={() => {
                                                                        this.onSelectLanguage('English')
                                                                    }} style={{
                                                                        backgroundColor: this.props.changedEventInfo.headerBackgroundColor,
                                                                        color: this.props.changedEventInfo.headerTextColor
                                                                    }}>
                                                                    <Flag code="gb" className="flagImages" />
                                                                    {selectedLanguageStrings.english}
                                                                </button>
                                                            ) : (
                                                                <button className="dropdown-item p-2" type="button"
                                                                    onClick={() => {
                                                                        this.onSelectLanguage('English')
                                                                    }}>
                                                                    <Flag code="gb" className="flagImages" />
                                                                    {selectedLanguageStrings.english}
                                                                </button>
                                                            )
                                                        }
                                                        {
                                                            (userStoredLanguage === 'Italian') ? (
                                                                <button className=" dropdown-item p-2" type="button"
                                                                    onClick={() => {
                                                                        this.onSelectLanguage('Italian')
                                                                    }} style={{
                                                                        backgroundColor: this.props.changedEventInfo.headerBackgroundColor,
                                                                        color: this.props.changedEventInfo.headerTextColor
                                                                    }}>
                                                                    <Flag code="it" className="flagImages" />
                                                                    {selectedLanguageStrings.italian}
                                                                </button>
                                                            ) : (
                                                                <button className=" dropdown-item p-2" type="button"
                                                                    onClick={() => {
                                                                        this.onSelectLanguage('Italian')
                                                                    }}>
                                                                    <Flag code="it" className="flagImages" />
                                                                    {selectedLanguageStrings.italian}
                                                                </button>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )
                                    }

                                    {(this.state.eventMessageList && this.state.eventMessageList.length > 0) ? (
                                        <div className="col-12 menuItem"
                                            style={{ borderBottomColor: this.props.changedEventInfo.headerBackgroundColor }}>
                                            <div className="row" onClick={this.getEventMessages} data-toggle="modal"
                                                data-target="#eventMessagesModal">
                                                <button className="btn col-12 text-left p-2 bg-white" type="button">
                                                    <i style={{ color: `${iconColorNotSelected}` }} className="fas fa-envelope bg-white menuIcons"></i>
                                                    <label className="text-grey pl-2 pr-2 bg-white">
                                                        {selectedLanguageStrings.event_messages}
                                                    </label>
                                                    {(this.state.badgeNumber > 0) ? (
                                                        <span className="badge badge-light bg-danger  text-white">
                                                            {this.state.badgeNumber}
                                                        </span>
                                                    ) : (
                                                        <span></span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                        </div>
                                    )}


                                    {(this.props.roomsList && this.props.roomsList.length > 1) ? (
                                        <div className="col-12 menuItem"
                                            style={{ borderBottomColor: this.props.changedEventInfo.headerBackgroundColor }}>
                                            <div className="row" onClick={this.props.goRoomPage}>
                                                <button className="col-12 text-left p-2 bg-white" type="button">
                                                    <i style={{ color: `${iconColorNotSelected}` }} className="fas fa-door-open bg-white menuIcons"></i>
                                                    <label
                                                        className="text-grey pl-2 bg-white"> {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.select_room : selectedLanguageStrings.select_room}</label>
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row">
                                        </div>
                                    )}

                                    {/*(this.props.parentInfo.currentLibraryElement && this.props.parentInfo.currentLibraryElement.title) ? (
                                            <div className="col-12 menuItem" style={{ borderBottomColor: this.props.changedEventInfo.headerBackgroundColor }} >
                                                <div className="row" onClick={this.props.goLibraryPage}>
                                                    <button className="col-12 text-left p-2 bg-white" type="button">
                                                        <i className="fas fa-book-open text-success bg-white menuIcons pt-1"></i>
                                                        <label className="text-grey pl-2 bg-white">{this.props.parentInfo.currentLibraryElement.title}</label>
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                                <div></div>
                                        )*/}




                                    {(this.props.changedEventInfo.mainMenu === undefined || this.props.changedEventInfo.mainMenu.questions) ? (
                                        (this.props.pageName === "QuestionScreen") ? (
                                            <div className="col-12 menuItem"
                                                style={{ borderBottomColor: this.props.changedEventInfo.headerBackgroundColor }}>
                                                <div className="row">
                                                    <button className="col-12 text-left p-2" type="button" style={{
                                                        backgroundColor: this.props.changedEventInfo.headerBackgroundColor,
                                                        color: this.props.changedEventInfo.headerTextColor
                                                    }}>
                                                        <i className="fas fa-question menuIcons"></i>
                                                        <label
                                                            className="pl-2">{selectedLanguageCustomStrings ? selectedLanguageCustomStrings.questions : selectedLanguageStrings.questions_menu}</label>
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="col-12 menuItem"
                                                style={{ borderBottomColor: this.props.changedEventInfo.headerBackgroundColor }}>
                                                <div className="row" onClick={this.props.goToQuestionPage}>
                                                    <button className="col-12 text-left p-2 bg-white" type="button">
                                                        <i style={{ color: `${iconColorNotSelected}` }} className="fas fa-question bg-white menuIcons"></i>
                                                        <label
                                                            className="text-grey pl-2 bg-white">{selectedLanguageCustomStrings ? selectedLanguageCustomStrings.questions : selectedLanguageStrings.questions_menu}</label>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        <div></div>
                                    )}

                                    <div>
                                        {rootChildrenComponent}
                                    </div>

                                    {/* <div className="col-12 menuItem" style={{ borderBottomColor: this.props.changedEventInfo.headerBackgroundColor }}>
                                            <div className="row" >
                                                <Link to={"/event/" + this.props.changedEventInfo.id + "/qrScanner"}>
                                                    <button className="col-12 text-left p-2 bg-white" type="button">
                                                        <i className="fas fa-qrcode text-secondary bg-white menuIcons"></i>
                                                        <label className="text-grey pl-2 bg-white">Qr Code Scanner</label>
                                                    </button>
                                                </Link>
                                            </div>
                                        </div> */}

                                    {this.props.changedEventInfo.mainMenu === undefined || this.props.changedEventInfo.mainMenu.logout ? (
                                        <div className="col-12 menuItem"
                                            style={{ borderBottomColor: this.props.changedEventInfo.headerBackgroundColor }}>
                                            <div className="row" onClick={this.props.goLogInPage}>
                                                <button className="col-12 text-left p-2 bg-white" type="button">
                                                    <i style={{ color: `${iconColorNotSelected}` }} className="fas fa-sign-in-alt bg-white menuIcons"></i>
                                                    <label
                                                        className="text-grey pl-2 bg-white"> {selectedLanguageCustomStrings ? selectedLanguageCustomStrings.logout : selectedLanguageStrings.logout}</label>
                                                </button>
                                            </div>
                                        </div>
                                    ) : <div></div>}
                                </div>
                            }
                            open={this.state.sidebarOpen}
                            onSetOpen={this.onSetSidebarOpen}
                            transitions={true}
                            styles={{
                                sidebar: {
                                    background: "white",
                                    width: "280px",
                                    // width: "50%",
                                    // height: '195px',
                                    zIndex: '1000',
                                    // overflowY: 'none',
                                    transition: "transform 9s",
                                    WebkitTransition: "-webkit-transform 9s ",
                                    willChange: "transform",
                                    // top: "35px",

                                },
                                root: {
                                    // marginLeft: "15px",
                                    position: "fixed",
                                },
                            }}
                        >
                            <div />
                        </Sidebar>

                    ) : (
                        <div></div>
                    )}
                    <div className={`serverHeartBeat ${counterServerHeartBeatClass}`} id="serverHeartBeatIcon"></div>
                </nav>



                <div className="modal fade" id="profileModal" tabIndex="-1" role="dialog" aria-labelledby="profileModal"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="profileModal"
                                    style={{ color: this.props.changedEventInfo.headerBackgroundColor }}>{selectedLanguageStrings.profile_modal_title}</h5>
                            </div>
                            <div className="modal-body mr-2 ml-2">
                                <form>
                                    <div className="form-group"
                                        style={{ color: this.props.changedEventInfo.headerBackgroundColor }}>
                                        <label
                                            htmlFor="participanName"><b>{selectedLanguageStrings.your_name}</b> ({selectedLanguageStrings.optional})</label>
                                        {(this.state.participantInfo && this.state.participantInfo.sName) ? (
                                            <input type="Name" className="form-control text-dark" id="participanName"
                                                defaultValue={this.state.participantInfo.sName} />
                                        ) : (
                                            <input type="Name" className="form-control" id="participanName" />
                                        )}
                                    </div>
                                    {(this.props.changedEventInfo.showEmailToParticipants === "Yes") ? (
                                        <div className="form-group"
                                            style={{ color: this.props.changedEventInfo.headerBackgroundColor }}>
                                            <label
                                                htmlFor="participantEmail"><b>{selectedLanguageStrings.your_email_address}</b> ({selectedLanguageStrings.optional})</label>
                                            {(this.state.participantInfo && this.state.participantInfo.sEmail) ? (
                                                <input type="email" className="form-control text-dark"
                                                    id="participantEmail" aria-describedby="emailHelp"
                                                    defaultValue={this.state.participantInfo.sEmail} />
                                            ) : (
                                                <input type="email" className="form-control " id="participantEmail"
                                                    aria-describedby="emailHelp" />
                                            )}
                                            <small id="emailHelp" className="form-text ml-1"
                                                style={{ color: this.props.changedEventInfo.headerBackgroundColor }}>{selectedLanguageStrings.profile_note}</small>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                    data-dismiss="modal">{selectedLanguageStrings.cancel}</button>
                                <button type="button" className="btn" style={{
                                    backgroundColor: this.props.changedEventInfo.headerBackgroundColor,
                                    color: this.props.changedEventInfo.headerTextColor
                                }} data-dismiss="modal"
                                    onClick={this.saveUserInfo}>{selectedLanguageStrings.save}</button>
                            </div>
                        </div>
                    </div>
                </div>


                <MessageModal
                    eventMessagesModal={this.state.eventMessagesModal}
                    eventMessageList={this.state.eventMessageList}
                    unreadImportantMessages={this.state.unreadImportantMessages}
                    eventImportantModal={this.state.eventImportantModal}
                    setBadgeNumber={this.setBadgeNumber.bind(this)}
                />

                <div id="pushmessageModalBtn" className="bg-success" data-toggle="modal"
                    data-target="#pushmessageModal">
                </div>
                <div className="modal fade" id="pushmessageModal" tabIndex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header p-2"
                                style={{ backgroundColor: this.props.changedEventInfo.primaryColor }}>
                                <h4 className="modal-title" id="exampleModalLabel"
                                    style={{ color: this.props.changedEventInfo.textColor }}>
                                    <i className="fas fa-bell mr-2"></i> {(this.state.pushedEventMessageShow != '') ? this.state.pushedEventMessageShow.title : ""}
                                </h4>
                            </div>
                            <div className="modal-body" style={{ color: this.props.changedEventInfo.primaryColor }}>
                                <h5>
                                    {(this.state.pushedEventMessageShow != '') ? this.state.pushedEventMessageShow.body : ""}
                                </h5>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" style={{
                                    backgroundColor: this.props.changedEventInfo.primaryColor,
                                    color: this.props.changedEventInfo.textColor
                                }}>Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="surveyModalBtn" className="bg-success" data-toggle="modal" data-target="#surveyModal">
                </div>
                {(this.state.showSurveyAlert === true) ? (
                    <div className="modal fade" id="surveyModal" tabIndex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header p-2"
                                    style={{ backgroundColor: this.props.changedEventInfo.primaryColor }}>
                                    <h4 className="modal-title" id="exampleModalLabel"
                                        style={{ color: this.props.changedEventInfo.textColor }}>
                                        <i className="far fa-list-alt mr-1"></i> {this.state.currentsurveyInfo.title}
                                    </h4>
                                    <button type="button" id="close-modal-survey" className="close-btn-modal"
                                        data-dismiss="modal" aria-label="Close"><span
                                            aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body"
                                    style={{ color: this.props.changedEventInfo.primaryColor }}>
                                    <h5>{this.state.currentsurveyInfo.popupmessage}: <b>{this.state.currentsurveyInfo.title}</b>
                                    </h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                        style={{
                                            backgroundColor: this.props.changedEventInfo.primaryColor,
                                            color: this.props.changedEventInfo.textColor
                                        }} onClick={() => this.props.goToSurveyPageModal(this.state.currentsurveyInfo)}>Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
        );
    }
}

// export default Header;
const mapStateToProps = state => {
    return {
        eventData: state.generalReducers.eventData,
        participantData: state.generalReducers.participantData,
        settingStatus: state.generalReducers.settingStatus,
        changedEventInfo: state.generalReducers.changedEventInfo,
        eventRoomsList: state.generalReducers.eventRoomsList,
        serverHeartBeat: state.generalReducers.serverHeartBeat
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateParticipantData: (participant, eventId, option, value) => dispatch(generalActions.updateParticipantData(participant, eventId, option, value)),
        onGetAllEventMessages: (eventId, callback) => dispatch(generalActions.getAllEventMessages(eventId, callback)),
        onGetSurveyInfoOnChange: (eventId, surveyId, callback) => dispatch(generalActions.getSurveyInfoOnChange(eventId, surveyId, callback)),
        onEventInfoChange: (eventId, callback) => dispatch(generalActions.getEventInfoOnChange(eventId, callback)),
        onGetEventRoomsOnChange: (eventId, callback) => dispatch(generalActions.getEventRoomsOnChange(eventId, callback)),
        onServerHeartBeatOnChange: (eventId) => dispatch(generalActions.getServerHeartBeatOnChange(eventId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

